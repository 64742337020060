import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import { useStartReviewTimerMutation } from '../../../api/campaignSlice';

function StartTimerDialog(props) {
    const [startReviewTimer] = useStartReviewTimerMutation();

    async function handleStart() {
        await startReviewTimer({
            campaignId: props.campaignId,
        });
        props.handleClose();
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>
                Start Timer
            </DialogTitle> 
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to start the timer? This will start the review timer for all staff reviews in this campaign that currently have no timer running.
                </DialogContentText>
            </DialogContent>           
            <DialogActions>
                <Button variant='outlined' onClick={props.handleClose}>
                    Cancel
                </Button>
                <Button variant='contained' onClick={handleStart} autoFocus>
                    Start
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default StartTimerDialog;
