import { TableCell, TableRow } from '@mui/material';
import React, { useContext } from 'react';
import { UserContext } from '../../Hooks/useUser';
import { useNavigate } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


function InventoryRow(props) {
    const { hasInventoryManagement } = useContext(UserContext);
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

    function goToItem(item) {
        if (hasInventoryManagement()) {
            navigate(`/inventory/${item.id}/details`);
        }
    }

    return (
        <TableRow onClick={() => goToItem(props.item)} sx={{
            '&:hover': !hasInventoryManagement() ? {
                cursor: 'default'
            } : {},

            ...(isSmScreen && {
                '& > td:nth-child(1)': { width: '40%' },
                '& > td:nth-child(2)': { width: '15%' },
                '& > td:nth-child(3)': { width: '10%' },
                '& > td:nth-child(4)': { width: '15%' },
                '& > td:nth-child(5)': { width: '7.5%' },
                '& > td:nth-child(6)': { width: '7.5%' },
            }),
        }}>
            <TableCell>{props.item.name}</TableCell>
            <TableCell>{props.item.vendor.name}</TableCell>
            <TableCell>{props.item.part_number}</TableCell>
            <TableCell>{props.item.category}</TableCell>
            <TableCell>
                <NumericFormat
                    value={props.item.cost / 100}
                    thousandSeparator={true}
                    displayType={'text'}
                    prefix={'$'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                />
            </TableCell>
            <TableCell>{props.item.is_active ? 'Active' : 'Inactive'}</TableCell>
        </TableRow>
    );
}

export default InventoryRow;
