import { styled, Stack, Divider } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import React from 'react';
import OptimizationHeader from './OptimizationHeader';
import OptimizationContext from './OptimizationContext';
import { motion } from 'framer-motion';
import OptimizationActions from './OptimizationActions';
import { useAcceptRecommendationMutation, useDeclineRecommendationMutation, useUndoCustomRecommendationMutation, useUndoRecommendationMutation } from '../../../../api/itemRecommendationSlice';
import LogOnEnter from '../../../CustomComponents/LogOnEnter';
import OptimizationBody from './OptimizationBody';

function Optimization(props) {
    const theme = useTheme();
    const [acceptRecommendation] = useAcceptRecommendationMutation();
    const [undoRecommendation] = useUndoRecommendationMutation();
    const [declineRecommendation] = useDeclineRecommendationMutation();
    const [undoCustomRecommendation] = useUndoCustomRecommendationMutation();

    const item = props.recommendation.type === 'addition' ? 
        props.recommendation.item : 
        props.item;

    function handleEdit() {
        if (props.recommendation.type === 'addition') {
            props.openItemView(props.recommendation);
        } else {
            props.openItemView(props.item);
        }
    }

    const handleUndo = async(event) => {
        event.stopPropagation();
        if (props.recommendation.type === 'custom_user_edit') {
            await undoCustomRecommendation({
                draft: props.draft,
                recommendation: props.recommendation,
            });
        } else {
            await undoRecommendation({
                draft: props.draft,
                recommendation: props.recommendation,
            });
        }
    }

    const handleAccept = async(event) => {
        event.stopPropagation();
        await acceptRecommendation({
            draft: props.draft,
            recommendation: props.recommendation,
        });
    }

    const handleDecline = async(event) => {
        event.stopPropagation();
        await declineRecommendation({
            draft: props.draft,
            recommendation: props.recommendation,
        });
    }

    const variants = {
        open: { 
            backgroundColor: `${theme.palette.background.white}`,
            border: '',
            transition: {
                duration: 0.3,
            }
        },
        accepted: { 
            transition: {
                duration: 0.3,
            },
        },
        rejected: { 
            transition: {
                duration: 0.3,
            }
        },
        custom_removal: { 
            transition: {
                duration: 0.3,
            }
        },
        custom_update: { 
            transition: {
                duration: 0.3,
            }
        },
        custom_swap: {
            transition: {
                duration: 0.3,
            }
        }
    };

    const context = {
        open: {
            opacity: 1,
            height: 'auto',
            marginTop: '8px',
            transition: {
                duration: 0.3,
                ease: 'easeInOut'
            }
        },
        accepted: {
            opacity: 0,
            height: 0,
            marginTop: 0,
            transition: {
                duration: 0.3,
                ease: 'easeInOut'
            },
        },
        rejected: {
            opacity: 0,
            height: 0,
            marginTop: 0,
            transition: {
                duration: 0.3,
                ease: 'easeInOut'
            }
        },
        custom_removal: {
            opacity: 0,
            height: 0,
            marginTop: 0,
            transition: {
                duration: 0.3,
                ease: 'easeInOut'
            }
        },
        custom_update: {
            opacity: 0,
            height: 0,
            marginTop: 0,
            transition: {
                duration: 0.3,
                ease: 'easeInOut'
            }
        },
        custom_swap: {
            opacity: 0,
            height: 0,
            marginTop: 0,
            transition: {
                duration: 0.3,
                ease: 'easeInOut'
            }
        }
    };

    return (
        <LogOnEnter action='viewed_recommendation' data={{ recommendation: props.recommendation }}>
            <OptimizationBox
                component={motion.div} initial={false} animate={props.recommendation.status} variants={variants} transition={{ duration: 1.2, type: 'spring', bounce: 0.1 }}
            >
                <Stack>
                    <Stack px={2} pt={1.5} pb={0.5} direction='row' alignItems='center'>
                        <Stack spacing={1} width='100%'>
                            <OptimizationHeader recommendation={props.recommendation} item={item} />
                            <OptimizationBody 
                                recommendation={props.recommendation} 
                                item={item} 
                                yearlyUsage={props.draft.card.yearly_stats?.usage_count} 
                            />
                        </Stack>
                    </Stack>
                    { !props.hideContext && 
                        <Stack component={motion.div} initial={false} variants={context} animate={props.recommendation.status}>
                            <Divider />
                            <Stack px={2}>
                                <OptimizationContext item={item} recommendation={props.recommendation} />
                            </Stack>
                        </Stack>
                    }
                    <Divider />
                    <OptimizationActions 
                        handleEdit={handleEdit} 
                        handleAccept={handleAccept} 
                        handleDecline={handleDecline} 
                        handleUndo={handleUndo} 
                        status={props.recommendation.status}
                        type={props.recommendation.type}
                        hideDetailsButton={props.hideDetailsButton}
                    />
                </Stack>
            </OptimizationBox>
        </LogOnEnter>
    )
}

const OptimizationBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.white,
    borderRadius: '4px',
}));

export default Optimization;