import { Button, Paper, Stack } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useUpdateUserMutation } from '../../api/userSlice';
import UserPermissionToggle from './UserPermissionToggle';

function UserPermissions(props) {
    const [submitEditUserRoles] = useUpdateUserMutation();

    const permissions = [
        { 
            id: 5, 
            name: 'User Management', 
            description: 'Allows the user to create and edit user accounts',
        },
        { 
            id: 6, 
            name: 'Inventory Management', 
            description: 'Allows the user to edit inventory items',
        },
        { 
            id: 7, 
            name: 'Card Management', 
            description: 'Allows the user to make and approve preference card edits',
        },
        {
            id: 9,
            name: 'Recommendation Reviewer',
            description: 'Allows the user access to the staff review portal for reviewing physician approved recommendations',
        }
    ];
    

    function handleCheck(role) {
        props.setSelectedIds([...props.selectedIds, role.id]);
    }

    function handleUncheck(role) {
        props.setSelectedIds(props.selectedIds.filter(id => id !== role.id))
    }


    const handleCancel = ()=> {
        props.setSelectedIds([...props.existingIds]);
    }

    const updateRoles = async () => {
        await submitEditUserRoles({
            user: props.user,
            roles: props.selectedIds.map((roleId) => {
                return permissions.find((permission) => {
                    return permission.id === roleId;
                });
            })
        })
    }

    return (
        <Paper elevation={2}>
            <Stack spacing={2} px={4} pt={4} pb ={4}>
                <Box sx={{ font: 'normal normal 16px/24px Roboto' , fontWeight : '500'}}>
                    Additional User Permissions
                </Box>
                { permissions.map((permission) => (
                    <UserPermissionToggle 
                        key={permission.id}
                        permission={permission}
                        handleCheck={handleCheck}
                        handleUncheck={handleUncheck}
                        isChecked={() => props.selectedIds.includes(permission.id)}
                        selectedIds = {props.selectedIds}
                    />
                ))}
            </Stack>
            {
                props.showCancelButton &&
                <Stack direction='row' pl = {2} pr={1} pb={1} justifyContent='space-between'>
                    <Button color = 'error' onClick = {handleCancel}> Cancel</Button>
                    <Button 
                        variant='text' 
                        color='primary' 
                        onClick={updateRoles}>Save</Button>
                </Stack>
            }

        </Paper>
    )
}


export default UserPermissions;