import { Slider, Stack, Typography } from '@mui/material';
import React from 'react';

function HospitalSiteSettings(props) {
    function handleHighImpactThresholdChange(newValue) {
        props.setHospitalData({
            ...props.hospitalData,
            high_impact_threshold: (newValue / 100)
        });
    }

    return (
        <Stack p={2} spacing={2}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography variant='normal' sx={{ fontSize: '16px' }}>High Impact Threshold: {Math.round((props.hospitalData.high_impact_threshold) * 100)}%</Typography>
                <Slider value={Math.round((props.hospitalData.high_impact_threshold) * 100)} onChange={(e) => handleHighImpactThresholdChange(e.target.value)} sx={{ maxWidth: '40%' }}/>
            </Stack>
        </Stack>
    )
}

export default HospitalSiteSettings;
