import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { Sync, EditRounded } from '@mui/icons-material';
import ReplaceItem from './ReplaceItem';
import { useAcceptRecommendationWithChangesMutation } from '../../../../api/itemRecommendationSlice';
import usePartnerConfigs from '../../../Hooks/usePartnerConfigs';

function EditItemPanel(props) {
    const [acceptRecommendationWithChanges] = useAcceptRecommendationWithChangesMutation();
    const { isCardComparisonActive } = usePartnerConfigs(props.physician);

    function handleEdit() {
        props.setIsEditing(true);
    }

    const acceptCustomSwap = async(newItem) => {
        const customValue = Math.round((props.item.open_quantity + (props.item.hold_quantity * 0.3)) * props.item.cost * (props.draft.card.yearly_stats?.usage_count ?? 1)) - Math.round((props.item.open_quantity + (props.item.hold_quantity * 0.3)) * newItem.cost * (props.draft.card.yearly_stats?.usage_count ?? 1));

        await acceptRecommendationWithChanges({
            draft: props.draft,
            recommendation: props.recommendation,
            status: 'custom_swap',
            customValue: customValue,
            newItem: newItem
        });

        props.closeItemInfo();
    }

    const [isShowingReplace, setIsShowingReplace] = React.useState(false);
    const item = props.item ?? props.recommendation.item;
    const openQuantity = item.open_quantity ?? props.recommendation.context.open;
    const holdQuantity = item.hold_quantity ?? props.recommendation.context.hold;

    return (
        <Stack p={2} direction='row' alignItems='center' justifyContent='space-between'>
            { item.is_removed ? 
                <Typography variant='heavy' sx={{ fontSize: '14px' }}><strong>Item Removed</strong></Typography> :
                <Stack spacing={0.5}>
                    <Typography variant='heavy' sx={{ fontSize: '14px' }}><strong>Open Quantity:</strong> {openQuantity}</Typography>
                    <Typography variant='heavy' sx={{ fontSize: '14px' }}><strong>Hold Quantity:</strong> {holdQuantity}</Typography>
                </Stack>
            }
            <Stack direction='row' spacing={1}>
                { isCardComparisonActive() && 
                    <Button variant='outlined' color='primary' onClick={() => setIsShowingReplace(true)} startIcon={<Sync />}>Replace</Button>
                }
                { (!props.recommendation || props.recommendation.type !== 'addition') && !props.item.is_removed && props.recommendation?.status !== 'accepted' && 
                    <Button variant='outlined' color='primary' onClick={handleEdit} startIcon={<EditRounded />}>Edit</Button>
                }
            </Stack>
            <ReplaceItem item={item} draft={props.draft} open={isShowingReplace} setOpen={setIsShowingReplace} swapItem={acceptCustomSwap} />
        </Stack>
    )
}

export default EditItemPanel;
