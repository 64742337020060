import { Delete, Upload } from '@mui/icons-material';
import { Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import OrganizationDropdown from '../../OrganizationDropDown';

function HospitalSiteDetails(props) {

    function handleNameChange(newName) {
        props.setHospitalData({
            ...props.hospitalData,
            name: newName
        })
    }

    function handleDeleteImage() {
        props.setFiles([]);
    }

    function handleUploadImage(files) {
        props.setFiles(files);
    }


    return (
        <Stack p={2} spacing={4}>
            <Typography variant='heavy' sx={{ fontSize: '16px' }}>Site Name</Typography>
            <TextField 
                fullWidth
                variant='outlined'
                value={props.hospitalData.name}
                onChange={(e) => handleNameChange(e.target.value)}
            />
            <OrganizationDropdown setData={props.setHospitalData} currentData={props.hospitalData} isCreating={props.isCreating}/>  
            <Stack>
                <Typography variant='heavy' sx={{ fontSize: '16px' }}>Site Logo (optional)</Typography>
                { props.files.length > 0 ? 
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Typography variant='normal' sx={{ fontSize: '16px' }}>{props.files[0].name}</Typography>
                        <IconButton onClick={handleDeleteImage}>
                            <Delete />
                        </IconButton>
                    </Stack>
                    :
                    <Button variant='outlined' startIcon={<Upload />} component='label'>
                        Upload Logo
                        <input hidden accept='image/*' type='file' onChange={(e) => handleUploadImage(e.target.files)} />
                    </Button>
                }
            </Stack>
        </Stack>
    );
}

export default HospitalSiteDetails;