import { Chip, TableCell, TableRow } from '@mui/material';
import format from 'date-fns/format/index.js';
import parseISO from 'date-fns/parseISO/index.js';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../Hooks/useUser';

function UserRow(props) {
    const { hasUserManagement } = useContext(UserContext);
    const navigate = useNavigate();

    function goToUser(user) {
        if (hasUserManagement()) {
            navigate(`/users/${user.id}`);
        }
    }

    return (
        <TableRow onClick={() => goToUser(props.user)} sx={{ 
            '&:hover': !hasUserManagement() ? {
                cursor: 'default'
            } : {}
        }}>
            <TableCell>{props.user.name}</TableCell>
            <TableCell>
                { props.user.roles.map((role) => (
                    <Chip size='small' key={role.id} label={role.name} p={1} m={1}/>
                ))}
            </TableCell>
            <TableCell>{props.user.last_login ? format(parseISO(props.user.last_login), 'M/d/yy') : 'Never'}</TableCell>
            <TableCell>{props.user.last_active ? format(parseISO(props.user.last_active), 'M/d/yy') : 'Not active in past two weeks'}</TableCell>
            <TableCell>Active</TableCell>
        </TableRow>
    );
}

export default UserRow;
