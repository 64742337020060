import { Delete, Upload } from '@mui/icons-material';
import { Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateOrganizationMutation, useUpdateOrganizationMutation } from '../../api/apiSlice';
import QuantityButtonGroup from '../CustomComponents/QuantityButtonGroup';

function OrganizationConfiguration(props) {
    const navigate = useNavigate();
    const [ organizationData, setOrganizationData] = useState(props.isCreating ? {
        name: '',
        logo: '',
        locked_account_threshold: 5,
    } : props.organization);
    const [ files, setFiles ] = useState([]);
    const [ submitCreateOrganization ] = useCreateOrganizationMutation();
    const [ updateOrganization ] = useUpdateOrganizationMutation();

    const handleSave = async() => {
        var requestData;
        if (files.length > 0) {
            requestData = new FormData();
            requestData.append('name', organizationData.name);
            requestData.append('logo', files[0]);
            requestData.append('locked_account_threshold', organizationData.locked_account_threshold);
        } else {
            delete organizationData.logo;
            requestData = organizationData;
        }

        if (props.isCreating) {
            submitCreateOrganization({
                data: requestData
            });
        } else {
            updateOrganization({
                organizationId: organizationData.id,
                data: requestData
            });
        }

        navigate('/superSettings/organizations');
    }

    const handleAccountThresholdChange = (newValue) => {
        setOrganizationData({
            ...organizationData,
            locked_account_threshold: newValue
        });
    }

    return (
        <Stack p={4} direction={'row'} alignItems={'center'}>
            <Stack spacing={2} width={{ md: '50%'}}>
                { props.isCreating && <Typography variant='bold' sx={{ fontSize: '22px' }}>Create New Organization</Typography>}
                { !props.isCreating && <Typography variant='bold' sx={{ fontSize: '22px' }}>Organization Details</Typography>}
                <Typography variant='heavy' sx={{ fontSize: '16px' }}>Name</Typography>
                <TextField 
                    fullWidth
                    variant='outlined'
                    value={organizationData.name}
                    onChange={(e) => setOrganizationData({ ...organizationData, name: e.target.value })}
                />
                <Typography variant='heavy' sx={{ fontSize: '16px' }}>Logo (optional)</Typography>
                { files.length > 0 ? 
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Typography variant='normal' sx={{ fontSize: '16px' }}>{files[0].name}</Typography>
                        <IconButton onClick={() => setFiles([])}>
                            <Delete />
                        </IconButton>
                    </Stack>
                    :
                    <Button variant='outlined' startIcon={<Upload />} component='label'>
                        Upload Logo
                        <input hidden accept='image/*' type='file' onChange={(e) => setFiles(e.target.files)} />
                    </Button>
                }
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Typography variant='normal' sx={{ fontSize: '16px' }}>Maximum Login Attempts: {organizationData.locked_account_threshold}</Typography>
                    <QuantityButtonGroup quantity={organizationData.locked_account_threshold} setQuantity={handleAccountThresholdChange}/>
                </Stack>
                <Button variant='contained' sx={{ width: '30%', }} disabled={!organizationData.name} onClick={handleSave}>
                    Save
                </Button>
            </Stack>
            <Stack width={{ md: '50%'}} justifyContent={'center'}>
                { organizationData.logo ? <img src={organizationData.logo} alt='Organization Logo' height={'250px'} style={{ objectFit: 'contain' }}/> : null}
            </Stack>            
        </Stack>
    );
}

export default OrganizationConfiguration;