import { CampaignOutlined, Logout, Public } from '@mui/icons-material';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import LocalHospitalRoundedIcon from '@mui/icons-material/LocalHospitalRounded';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
import WysiwygRoundedIcon from '@mui/icons-material/WysiwygRounded';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText, Skeleton, Toolbar } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../../assets/docsi_logo.svg';
import { useAuth } from '../Hooks/useAuth';
import { UserContext } from '../Hooks/useUser';
import HospitalDropdown from '../HospitalDropdown';
import ConversationCount from './ConversationCount';

function SidebarContents(props) {
    const navigate = useNavigate();
    const { user, setUser, hasUserManagement, hasCardManagement, isSuperUser } = useContext(UserContext);
    const { logout } = useAuth();
    const location = useLocation();

    function handleLogout(event) {
        event.preventDefault();
        logout().then(() => {
            setUser(null);
            navigate('/login', {replace : true})
        });
    }

    const selectedIndex = useMemo(() => {
        if (location.pathname.split('/').includes('cards')) {
            return 'cards';
        } else if (location.pathname.split('/').includes('users')) {
            return 'users';
        } else if (location.pathname.split('/').includes('inventory')) {
            return 'inventory';
        } else if (location.pathname.split('/').includes('hospitals')) {
            return 'hospitals';
        } else if (location.pathname.split('/').includes('physicians')) {
            return 'physicians';
        } else if (location.pathname.split('/').includes('physicianTexting')) {
            return 'physicianTexting';
        } else if (location.pathname.split('/').includes('campaigns')) {
            return 'campaigns';
        }
    }, [location]);

    return (
        <>
            <Toolbar sx={{ padding: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                { user.hospital ? <img src={user.hospital.logo ? user.hospital.logo : logo} alt='Site Logo' style={{ objectFit: 'contain' }} width={200}/> : <Skeleton variant='rectangle' width={150} height={20} /> }
            </Toolbar>
            <Divider />
            <List>
                <ListItemButton key={0} selected={selectedIndex === 'cards'} onClick={() => navigate('/cards')}>
                    <ListItemIcon>
                        <WysiwygRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Card Templates' />
                </ListItemButton>
                { hasUserManagement() && 
                    <ListItemButton key={1} selected={selectedIndex === 'users'} onClick={() => navigate('/users')}>
                        <ListItemIcon>
                            <PeopleOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary='Users' />
                    </ListItemButton>
                }
                <ListItemButton key={2} selected={selectedIndex === 'inventory'} onClick={() => navigate('/inventory')}>
                    <ListItemIcon>
                        <Inventory2OutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Inventory' />
                </ListItemButton>
                { hasCardManagement() && 
                    <ListItemButton key={3} selected={selectedIndex === 'physicians'} onClick={() => navigate('/physicians')}>
                        <ListItemIcon>
                            <LocalHospitalRoundedIcon />
                        </ListItemIcon>
                        <ListItemText primary='Physicians' />
                    </ListItemButton>
                }
                {/* Change this to check for a campaign management role in the furture */}
                { isSuperUser() &&
                    <ListItemButton key={8} selected={selectedIndex === 'campaigns'} onClick={() => navigate('/campaigns')}>
                        <ListItemIcon>
                            <CampaignOutlined />
                        </ListItemIcon>
                        <ListItemText primary='Campaigns' />
                    </ListItemButton>
                }
            </List>
            <Divider />
            <List>
                <ListItemButton target="_blank" href = "https://docs.google.com/forms/d/e/1FAIpQLSdZk2yXi-G7yU_dhUurqcd_id2ffgXYXh46m0tNwW2EJJmD-Q/viewform" key={4} selected={selectedIndex === 'help'}>
                    <ListItemIcon>
                        <SupportRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Need Help?' />
                </ListItemButton>
                { isSuperUser() && 
                    <>
                        <ListItemButton key={5} selected={selectedIndex === 'hospitals'} onClick = {() => navigate(`/superSettings/hospitals/${user.hospital.id}`)}>
                            <ListItemIcon>
                                <Public />
                            </ListItemIcon>
                            <ListItemText primary='Super Settings'/>
                        </ListItemButton>
                        {/* <ListItemButton key={9} selected={selectedIndex === 9} onClick={() => navigate(`/organizations/${user.hospital?.organization_id}`)}>
                            <ListItemIcon>
                                <Public />
                            </ListItemIcon>
                            <ListItemText primary='Organization Settings' />
                        </ListItemButton> */}
                        <ConversationCount selectedIndex={selectedIndex} isSuperUser={isSuperUser()} navigate={() => navigate('/physicianTexting')} />
                    </>
                }
                <ListItemButton key={7} selected={selectedIndex === 'logout'} onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout />
                    </ListItemIcon>
                    <ListItemText primary='Logout' />
                </ListItemButton>
            </List>
            { isSuperUser() && 
                <Box sx={{ width: props.drawerWidth, position: 'fixed', bottom: 0 }}>
                    <HospitalDropdown />
                </Box>
            }
        </>
    );
}

export default SidebarContents;