import React from 'react';
import { Box, Stack } from '@mui/material';
import { theme } from '../../../theme';

function CampaignProgressBar(props) {
    const steps = [];
    const successBackgroundColor = props.isReviewed ? theme.palette.green.taskText : theme.palette.blue.main;

    for (let i = 0; i < props.num_steps; i++) {
        steps.push(
            <Box sx={{ 
                display: 'grid', 
                width: props.num_steps === 1 ? '100%' : 'calc(100% / ' + props.num_steps + ')', 
                height: '10px',
                backgroundColor: i < props.current_step ? successBackgroundColor : theme.palette.background.stepProgress,
                borderTopLeftRadius: i === 0 ? '6px' : '0px', 
                borderBottomLeftRadius: i === 0 ? '6px' : '0px',
                borderTopRightRadius: (i === props.num_steps - 1 || props.num_steps === 1) ? '6px' : '0px',
                borderBottomRightRadius: (i === props.num_steps - 1 || props.num_steps === 1) ? '6px' : '0px',
            }}/>
        );
    }

    return (
        <Stack direction='row' spacing={0.25}>
            { steps.map((step) => (
                step
            ))}
        </Stack>
    )
}


export default CampaignProgressBar;
