import { useState, createContext } from 'react';

export default function useUser() {
    const getUser = () => {
        return JSON.parse(localStorage.getItem('user'));
    };

    const [user, setUser] = useState(getUser());

    const saveUser = (newUser) => {
        if (newUser === null) {
            localStorage.removeItem('user');
        } else {
            localStorage.setItem('user', JSON.stringify(newUser));
        }
        setUser(newUser);
    };

    return {
        setUser: saveUser,
        user,
    };
}

export const UserContext = createContext(null);

export function UserContextProvider({ children }) {
    const getUser = () => {
        return JSON.parse(localStorage.getItem('user'));
    };

    const [user, setUser] = useState(getUser());

    const saveUser = (newUser) => {
        if (newUser === null) {
            localStorage.removeItem('user');
        } else {
            localStorage.setItem('user', JSON.stringify(newUser));
        }
        setUser(newUser);
    };

    function isSuperUser() {
        const superUserRole = user.roles.filter((role) => role.name === 'Super Admin');
        return superUserRole.length > 0;
    }

    function hasCardManagement() {
        return isSuperUser() || user.roles.find((role) => role.name === 'Card Management') !== undefined;
    }

    function hasUserManagement() {
        return isSuperUser() || user.roles.find((role) => role.name === 'User Management') !== undefined;
    }

    function hasInventoryManagement() {
        return isSuperUser() || user.roles.find((role) => role.name === 'Inventory Management') !== undefined;
    }

    const userContext = {
        user,
        setUser: saveUser,
        isSuperUser,
        hasCardManagement,
        hasUserManagement,
        hasInventoryManagement,
    };

    return <UserContext.Provider value={userContext}>{children}</UserContext.Provider>;
}
