import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetOrganizationQuery } from '../../api/apiSlice';
import LoadingIndicator from '../LoadingIndicator';
import OrganizationConfiguration from './OrganizationConfiguration';

function OrganizationView() {
    const params = useParams();
    const navigate = useNavigate();
    const { data: organizationData, isLoading, isSuccess } = useGetOrganizationQuery(params.organizationId);

    function handleClick() {
        navigate('/superSettings/hospitals/create', { state: { organizationId: organizationData.id, organizationName: organizationData.name } });
    }

    return (
        isLoading && <LoadingIndicator /> ||
        !isLoading && isSuccess &&
        <>
            <Stack display='grid' alignItems='center'>
                <OrganizationConfiguration organization={organizationData} key={organizationData.id} />
            </Stack>
            <Stack direction='row' justifyContent='left' alignItems='center' spacing={2} p={4}>
                <Button
                    variant='contained'
                    startIcon={<Add />}
                    onClick={() => handleClick()}
                >
                    Create New Hospital
                </Button>
            </Stack>
        </>
    );
}

export default OrganizationView;